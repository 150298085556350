import React from "react";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import VehicleService from "../services/VehicleService";
import LocationService from "../services/LocationService";

import useAPI from "useAPI";
import { Autocomplete } from "@material-ui/lab";
import history from "utils/history";
import { useLocation } from "react-router-dom";
import { Context } from "Store";
import types from "Reducer/types";
import Loading from "components/Loading";
import CarouselService from "services/CarouselService";

const useStyles = makeStyles((theme) => ({
  ...styles,
  float: {
    margin: theme.spacing(1),
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
  floatPin: {
    margin: theme.spacing(1),
    top: "auto",
    right: "auto",
    bottom: 30,
    left: 20,
    position: "fixed",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  newButton: { marginTop: 20, color: "white" },
}));

export const usePosition = () => {
  const [position, setPosition] = React.useState({});
  const [error, setError] = React.useState(null);

  const onChange = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (error) => {
    setError(error.message);
  };
  React.useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      console.log("Not Supported");
      setError("Geolocation is not supported");
      return;
    }
    let watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);
  return { ...position, error };
};

function distance(lat1, lon1, lat2, lon2) {
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a =
    0.5 -
    c((lat2 - lat1) * p) / 2 +
    (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

function SelectVehicle() {
  const classes = useStyles();
  const api = useAPI();
  const [selectedVehicle, setSelectedVehicle] = React.useState("");
  const [selectedCarousel, setSelectedCarousel] = React.useState("");
  const [isFromPark, setIsFromPark] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const location = useLocation();
  const { latitude, longitude } = usePosition();
  const [carousels, setCarousels] = React.useState([]);

  const [, setLocationList] = React.useState([]);
  const [userLocation, setUserLocation] = React.useState("");
  const { state, dispatch } = React.useContext(Context);

  const { vehicles = [] } = state;

  React.useEffect(() => {
    if (location.pathname.includes("park")) {
      setIsFromPark(true);
      if (state.parked) {
        history.replace("/");
      }
    }
  }, []);

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      var ans = Math.min(); // infinity
      var nearestLocationObj = {};

      setLocationList(
        res.map((item) => {
          const obj = {
            id: item.id,
            name: item.name + " - " + item.zipcode,
            value: item.id,
            latitude: item.latitude,
            longitude: item.longitude,
          };
          var d = distance(latitude, longitude, item.latitude, item.longitude);

          if (d <= ans) {
            ans = d;
            nearestLocationObj = obj;
          }
          return obj;
        })
      );
      setUserLocation(nearestLocationObj.id);
    });
  };

  const fetechCarousels = async () => {
    CarouselService.getCarouselForLocation(localStorage.userLocationId)
      .then((res) => {
        setCarousels(res);
        if (res && res.length > 0) {
          if (
            typeof localStorage.carouselId !== "undefined" &&
            localStorage.carouselId !== null
          ) {
            setSelectedCarousel(
              res.find((element) => element.id == localStorage.carouselId)
                ? res.find((element) => element.id == localStorage.carouselId)
                : res[0]
            );
          } else {
            setSelectedCarousel(res[0]);
            localStorage.setItem("carouselId", res[0].id);
          }
        }
      })
      .catch((res) => {
        console.log("No carousels there", res);
      });
  };

  const fetchVehicles = async () => {
    const res = await VehicleService.getVehicles();
    dispatch({ type: types.SET_VEHICLES, payload: res });
    if (res.length > 0) {
      setSelectedVehicle(
        res.find((element) => element.is_default)
          ? res.find((element) => element.is_default)
          : res[0]
      );
    } else {
      const newPath = location.pathname.includes("park")
        ? "/park/vehicle/new"
        : "/vehicles/new";
      history.replace(newPath, { from: location.pathname });
    }
    setIsLoading(false);
  };

  const onNewVehicle = () => {
    if (isFromPark) {
      history.push({
        pathname: "/park/vehicle/new",
        from: location.pathname,
        state: { userLocationId: userLocation },
      });
      return;
    }
    history.push("/vehicles/new");
  };

  const onEditVehicle = () => {
    if (isFromPark) {
      localStorage.setItem("carouselId", selectedCarousel.id);
      localStorage.setItem(
        "selectedCarousel",
        JSON.stringify(selectedCarousel)
      );
      history.push({
        pathname: "/park/carousel-detail/" + selectedVehicle.id,
        from: location.pathname,
        state: {
          userLocationId: userLocation,
          carouselsId: selectedCarousel.id,
        },
      });
      return;
    }
    history.push("/vehicle/" + selectedVehicle.id + "/edit", {
      from: location.pathname,
    });
  };

  React.useEffect(() => {
    VehicleService.init(api);
    fetchVehicles();
    CarouselService.init(api);
    fetechCarousels();
  }, [api]);

  React.useEffect(() => {
    LocationService.init(api);
    fetchLocationList();
  }, [latitude, longitude]);

  if (!vehicles.length && isLoading) {
    return <Loading />;
  }

  return (
    <AuthenticatedContainer>
      <>
        <GridItem xs={12} sm={12} md={8} align="center" style={{ padding: 10 }}>
          <Typography variant="body1" color="primary">
            {isFromPark ? "Select OR Add Vehicle To Park" : "Manage Vehicle"}
          </Typography>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} align="center"></GridItem>
        <GridItem xs={12} sm={12} md={8} align="center">
          <Box p={3}>
            <Autocomplete
              id="combo-box-demo"
              options={vehicles}
              getOptionLabel={(option) => option.title || ""}
              style={{ width: 300, textAlign: "center" }}
              value={selectedVehicle}
              onChange={(event, newValue) => {
                setSelectedVehicle(newValue);
              }}
              // inputValue={selectedVehicle}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Vehicle"
                  variant="outlined"
                />
              )}
            />
          </Box>

          {isFromPark ? (
            <Box p={3}>
              <Autocomplete
                id="combo-box-demo"
                options={carousels}
                getOptionLabel={(option) => option.nick_name || ""}
                style={{ width: 300, textAlign: "center" }}
                value={selectedCarousel}
                onChange={(event, newValue) => {
                  setSelectedCarousel(newValue);
                }}
                hidden={localStorage.scanned}
                // inputValue={selectedVehicle}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Carousel"
                    variant="outlined"
                  />
                )}
              />
            </Box>
          ) : null}
          <Box pd={3} px={3}>
            <Button
              onClick={onEditVehicle}
              color="primary"
              variant="contained"
              fullWidth
              className={classes.newButton}
            >
              {isFromPark ? "Continue" : "Edit Selected Vehicle"}
            </Button>
          </Box>
        </GridItem>
        <GridItem
          xs={12}
          sm={12}
          md={8}
          align="center"
          style={{ color: "black", marginTop: 20 }}
        >
          OR
        </GridItem>

        <GridItem xs={12} sm={12} md={8} align="center">
          <Box p={2}>
            <Button
              onClick={onNewVehicle}
              color="primary"
              variant="contained"
              fullWidth
              className={classes.newButton}
            >
              Add New Vehicle
            </Button>
          </Box>
        </GridItem>
      </>
    </AuthenticatedContainer>
  );
}

export default SelectVehicle;
