import * as React from "react";
import { Grid, Button } from "@material-ui/core";
import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCVC,
} from "./commonTextFields";
import { CardNumberElement } from "@stripe/react-stripe-js";
import {
  // CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import CardService from "../../services/CardService";
import useAPI from "useAPI";
import { Context } from "Store";
import { useParams, useLocation } from "react-router-dom";
import history from "utils/history";

export default function CreditCardDetail() {
  const api = useAPI();
  const elements = useElements();
  const stripe = useStripe();
  const [state, setState] = React.useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: null,
    expiredError: null,
    cvcError: null,
  });
  const [isFromPark, setIsFromPark] = React.useState(false);
  const store = React.useContext(Context);
  let { id } = useParams();
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.includes("park")) {
      setIsFromPark(true);
      if (store.state.parked) {
        history.replace("/");
      }
    }
  }, []);

  const onElementChange = (field, errorField) => ({
    complete,
    error = { message: null },
  }) => {
    console.log("1111111111111", field, errorField, complete, error);
    setState({ ...state, [field]: complete, [errorField]: error.message });
  };

  const addStripeCard = async () => {
    // if (!cardNumberError && !expiredError && cvcError) {
    // const cardElement = elements.getElement(CardElement);
    const cardElement = elements.getElement(CardNumberElement);

    console.log("123123", cardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      CardService.init(api);
      await CardService.storeCard(paymentMethod);
      console.log("[PaymentMethod]", paymentMethod);
      if (isFromPark) {
        setTimeout(() => {
          history.push("/park/carousel-detail" + id);
        }, 1000);
      } else {
        setTimeout(() => {
          history.push("/payment-cards");
        }, 1000);
      }
    }
  };
  const { cardNumberError, expiredError, cvcError } = state;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <StripeTextFieldNumber
          error={Boolean(cardNumberError)}
          labelErrorMessage={cardNumberError}
          onChange={onElementChange("cardNumberComplete", "cardNumberError")}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeTextFieldExpiry
          error={Boolean(expiredError)}
          labelErrorMessage={expiredError}
          onChange={onElementChange("expiredComplete", "expiredError")}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <StripeTextFieldCVC
          error={Boolean(cvcError)}
          labelErrorMessage={cvcError}
          onChange={onElementChange("cvcComplete", "cvcError")}
        />
      </Grid>
      <Button
        color="primary"
        variant="contained"
        onClick={() => addStripeCard()}
        fullWidth
      >
        {"Add Card"}
      </Button>
    </Grid>
  );
}
