/* eslint-disable react/display-name */

import React from "react";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Container, Row, Col } from "reactstrap";
import GridItem from "components/Grid/GridItem.js";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TermsAndConditions from "./TermsAndConditions";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip
} from "@material-ui/core";
import useAPI from "useAPI";
import UserService from "../services/UserService";
import InputMask from "react-input-mask";
import { useLocation } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";

const useStyles = makeStyles(() => ({
  ...styles,
}));
export const ProfileComponent = () => {
  const classes = useStyles();
  const { user } = useAuth0();
  const location = useLocation();
  const states = require("./Settings/States");

  const [saving, setSaving] = React.useState(false);
  const [systemUser, setSystemUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const api = useAPI();

  const [values, setValues] = React.useState({});
  const [errors, setErrors] = React.useState({
    phone: "",
  });

  const handleClose = async (tnc) => {
    setValues({ ...values, is_new: 0, is_agree: tnc ? 1 : 0 });
    await UserService.updateUser({
      ...values,
      is_new: 0,
      is_agree: tnc ? 1 : 0,
    });
    setOpen(false);
  };

  const fetchUser = async () => {
    api.get("user").then((res) => {
      let sysUser = res.data;
      console.log(sysUser);
      Object.keys(sysUser).forEach((key) => {
        sysUser[key] = sysUser[key] ? sysUser[key] : "";
      });
      console.log("HHEEREEE", sysUser);
      setValues({ ...sysUser });
      setSystemUser(sysUser);
      if (location.state && location.state.isRedirectFromHome === true) {
        console.log("isRedirectFromHome");
        setOpen(res.data.is_new === 1 ? true : false);
      }
    });
  };

  React.useEffect(() => {
    UserService.init(api);
    fetchUser();
  }, [api]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSave = async () => {
    let error = 0;
    let newErrors = errors;
    ["phone"].forEach((field) => {
      if (values[field] === "") {
        newErrors[field] = "error";
        error = 1;
      }
    });

    setErrors({ ...newErrors });

    if (error) return;

    console.log("VAAALUESS", values);
    setSaving(true);
    await UserService.updateUser(values);
    fetchUser();
    setSaving(false);
  };

  if (!systemUser) {
    return (
      <AuthenticatedContainer>
        <Container className="m-5">
          <Row className="align-items-center profile-header mb-5 text-center text-md-left">
            <Col md={2}>
              <img
                src={user.picture}
                alt="Profile"
                className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
              />
            </Col>
            <Col>
              <p className="lead text-muted">{user.email}</p>
            </Col>
          </Row>
        </Container>
      </AuthenticatedContainer>
    );
  }

  return (
    <AuthenticatedContainer>
      <Container className="m-5">
        <GridItem xs={12} sm={12} md={8} align="center">
          <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Terms and Conditions"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <TermsAndConditions />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleClose(false);
                }}
                color="primary"
              >
                Disagree
              </Button>
              <Button
                onClick={() => {
                  handleClose(true);
                }}
                color="primary"
                autoFocus
              >
                Agree
              </Button>
              {saving && (
                <CircularProgress
                  size={14}
                  // className={classes.buttonProgress}
                />
              )}
            </DialogActions>
          </Dialog>
        </GridItem>
        <Row className="align-items-center profile-header mb-5 text-center text-md-left">
          <Col md={2}>
            <img
              src={user.picture}
              alt="Profile"
              className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
            />
          </Col>
          <Col>
            <p className="lead text-muted">{user.email}</p>
          </Col>
        </Row>
        <Row>
          <Box pb={5}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              variant="outlined"
              value={values.name}
              onChange={handleChange("name")}
              type="text"
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              id="address"
              label="Address"
              value={values.address}
              variant="outlined"
              onChange={handleChange("address")}
              type="text"
              fullWidth
              multiline
              rows={1}
            />

            <TextField
              autoFocus
              margin="dense"
              id="city"
              label="City"
              value={values.city}
              variant="outlined"
              onChange={handleChange("city")}
              type="text"
              fullWidth
            />

            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <InputLabel id="state-id">State</InputLabel>
              <Select
                label="State"
                value={values.state}
                required
                onChange={handleChange("state")}
              >
                <MenuItem
                  disabled
                  value=""
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose State
                </MenuItem>

                {states &&
                  states.map((state) => (
                    <MenuItem
                      value={`${state}`}
                      key={`${state}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${state}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {/* <TextField
              autoFocus
              margin="dense"
              id="state"
              label="State"
              variant="outlined"
              value={values.state}
              onChange={handleChange("state")}
              type="text"
              fullWidth
            /> */}

            {/* <TextField
              autoFocus
              margin="dense"
              id="zipcode"
              label="Zipcode"
              variant="outlined"
              value={values.zipcode}
              onChange={handleChange("zipcode")}
              type="number"
              fullWidth
            /> */}

            <InputMask
              mask="99999"
              value={values.zipcode}
              disabled={false}
              onChange={handleChange("zipcode")}
              maskChar=" "
            >
              {() => (
                <TextField
                  autoFocus
                  margin="dense"
                  id="zipcode"
                  label="Zip Code"
                  variant="outlined"
                  value={values.zipcode}
                  type="text"
                  fullWidth
                />
              )}
            </InputMask>

            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              variant="outlined"
              value={values.email}
              onChange={handleChange("email")}
              type="email"
              disabled
              fullWidth
            />

            {/* <TextField
              autoFocus
              margin="dense"
              id="phone"
              label="Phone"
              variant="outlined"
              value={values.phone}
              onChange={handleChange("phone")}
              type="text"
              fullWidth
            /> */}

            <TextField
              error={errors.phone === "error"}
              margin="dense"
              id="phone"
              label="Phone*"
              variant="outlined"
              value={values.phone}
              onChange={handleChange("phone")}
              fullWidth
              type="tel"
              InputProps={{
                endAdornment: values.phone_verified ? (
                  <CheckCircleIcon style={{ color: "#006600" }} />
                ) : (
                  <Tooltip title="Unverified Phone Number" aria-label="add">
                    <IconButton>
                      {" "}
                      <ErrorOutlinedIcon style={{ color: "red" }} />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />

            {/* <InputMask
              mask="+1 (999) 999 9999"
              value={values.phone}
              disabled={false}
              onChange={handleChange("phone")}
              maskChar=" "
            >
              {() => (
                <TextField
                  error={errors.phone === "error"}
                  autoFocus
                  margin="dense"
                  id="phone"
                  label="Phone"
                  variant="outlined"
                  value={values.phone}
                  type="text"
                  fullWidth
                />
              )}
            </InputMask> */}

            {/* <TextField
              autoFocus
              margin="dense"
              id="licenseNumber"
              label="License #"
              variant="outlined"
              value={values.licenseNumber}
              onChange={handleChange("licenseNumber")}
              type="text"
              fullWidth
            /> */}

            {/* <TextField
              autoFocus
              margin="dense"
              id="licenseState"
              label="License State"
              variant="outlined"
              value={values.licenseState}
              onChange={handleChange("licenseState")}
              type="text"
              fullWidth
            /> */}
            {/* <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              className={classes.formControl}
            >
              <InputLabel id="license-state-id">LicenseState</InputLabel>
              <Select
                label="LicenseState"
                value={values.licenseState}
                required
                onChange={handleChange("licenseState")}
              >
                <MenuItem
                  disabled
                  value={""}
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose License State
                </MenuItem>

                {states &&
                  states.map((state) => (
                    <MenuItem
                      value={`${state}`}
                      key={`${state}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${state}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl> */}
            <Button
              onClick={handleSave}
              variant="contained"
              color="primary"
              fullWidth
              className="mt-3"
            >
              Save
            </Button>
            {saving && <CircularProgress size={24} />}
          </Box>
        </Row>
        {/* <Row>
          <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
        </Row> */}
      </Container>
    </AuthenticatedContainer>
  );
};

export default ProfileComponent;
