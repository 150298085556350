import Axios from "axios";

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getPlans = async () => {
  try {
    let cancel;
    const response = await api.get("/subscription-plans", {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const createPlan = async (id) => {
  try {
    let cancel;
    const response = await api.post("/plan/subscribe/" + id, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const SubscriptionService = {
  init,
  getPlans,
  createPlan,
};

export default SubscriptionService;
