const defaultState = {
  user: {},
  parked: false,
  retrieving: false,
  parkingSession: null,
  vehicles: [],
  error: null,
  notificationCount: 0,
  discountamount: {},
};

export default defaultState;
