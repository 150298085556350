import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import NotificationsIcon from "@material-ui/icons/Notifications";
// import SettingsIcon from "@material-ui/icons/Settings";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import { Link } from "@material-ui/core";
import history from "utils/history";
import { Context } from "Store";
import { useAuth0 } from "@auth0/auth0-react";
import useAPI from "useAPI";
import types from "Reducer/types";
import Badge from "@material-ui/core/Badge";
import Avatar from "@material-ui/core/Avatar";
import MenuIcon from "@material-ui/icons/Menu";
const roundRadius = 20;
// const deviceType = parser(req.headers['user-agent']).device.type || 'desktop';

const useStyles = makeStyles(() => ({
  roundedBottomBar: {
    width: "100%",
    borderTopLeftRadius: roundRadius,
    borderTopRightRadius: roundRadius,
    position: "fixed",
    bottom: 0,
    "& .MuiBottomNavigationAction-root": {
      "@media (max-width: 768px)": {
        minWidth: "auto",
        padding: "6px",
      },
    },
  },

  badgeTransform: {
    transform: "translate(-300%, 50%)",
  },
}));

const BottomTabs = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { state, dispatch } = React.useContext(Context);
  const { isAuthenticated } = useAuth0();
  const api = useAPI();

  React.useEffect(() => {
    if (isAuthenticated) {
      api
        .get("user")
        .then((res) => {
          let sysUser = res.data;
          Object.keys(sysUser).forEach((key) => {
            sysUser[key] = sysUser[key] ? sysUser[key] : "";
          });
          dispatch({ type: types.SET_USER, payload: sysUser });
        })
        .catch((e) => console.error(e));
    }
  }, [isAuthenticated]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "more") {
      history.push("/profile");
    }
    if (newValue === "notification") {
      history.push("/notifications");
    }
    if (newValue === "settings") {
      history.push("/settings");
    }
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels={false}
      style={{ backgroundColor: "#f1f1f1", zIndex: 1 }}
      className={classes.roundedBottomBar}
    >
      {(state.user.assignedPin && (
        <BottomNavigationAction
          value="recents"
          icon={
            <h5 style={{ width: "100px", minWidth: "80%" }}>
              <Link
                component="a"
                variant="body2"
                color="primary"
                onClick={() => {
                  console.info("Checking vechicle status.");
                }}
              >
                PIN: {state.user.assignedPin || "A1B2C3"}
              </Link>
            </h5>
          }
        />
      )) ||
        null}

      {/* transform : scale(1) translate(-60px, 10px) */}
      <BottomNavigationAction value="settings" icon={<MenuIcon />} />
      <BottomNavigationAction
        value="notification"
        icon={
          <Badge badgeContent={state.notificationCount} color="primary">
            <NotificationsIcon />
          </Badge>
        }
      />
      <BottomNavigationAction
        value="more"
        icon={<Avatar alt={state.user.name} src={state.user.picture} />}
      />
      <BottomNavigationAction value="help" icon={<LiveHelpOutlinedIcon />} />
    </BottomNavigation>
  );
};

export default BottomTabs;
