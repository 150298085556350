import React from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import AuthenticatedContainer from "components/AuthenticatedContainer";
import { Typography } from "@material-ui/core";
import "react-credit-cards/es/styles-compiled.css";
import CardForm from "./CardForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { getConfig } from "../../config";

const config = getConfig();
const stripePromise = loadStripe(config.stripePublishKey);
function PaymentCard() {
  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align="center">
        <Typography variant="h5" style={{ color: "#324152" }}>
          Add Card
        </Typography>
      </GridItem>

      <Elements stripe={stripePromise}>
        <CardForm />
      </Elements>
    </AuthenticatedContainer>
  );
}

export default PaymentCard;
