const types = {
  SET_USER: "SET_USER",
  SET_USER_PIN: "SET_USER_PIN",
  SET_PARKING: "SET_PARKING",
  SET_PARKED: "SET_PARKED",
  SET_RETRIEVING: "SET_RETRIEVING",
  SET_VEHICLES: "SET_VEHICLES",
  SET_CURRENT_PARKING_SESSION: "SET_CURRENT_PARKING_SESSION",
  SET_DISCOUNT_AMOUNT: "SET_DISCOUNT_AMOUNT",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
};

export default types;
